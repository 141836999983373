
$(document).on('keyup keypress', 'form', function(e) {
    var keyCode = e.keyCode || e.which;
    if (keyCode === 13) { 
    e.preventDefault();
    return false;
    }
});

    $(document).on('click', '#productType button', function() {
        $('#ddTireType').show();
        $('#dimensionHolder').addClass('col-xs-3').removeClass('col-xs-12');
        $('#dimensionHolder').addClass('padding-right-ajust');


        $('#productType .glyphicon').css("visibility","hidden");
        $(this).find('.glyphicon').css("visibility","visible");
        // console.log($(this).data('type'));

        if($(this).data('type') == 'sommardack') {
            $('#all form').attr('action', 'sok/reg/sommardack');
            $('#tireType').on('change', function() {
                
                $('#all form').attr('action', 'sok/reg/' + $('#tireType').val());
            });
        } else {
            $('#all form').attr('action', 'sok/reg/' + $(this).data('type'));
        }
        // console.log($('#all form').attr('action'));
    });


    $(document).on('click', '#selectRims', function() {
        $('#ddTireType').hide();
        $('#dimensionHolder').addClass('col-xs-12').removeClass('col-xs-3');
        $('#dimensionHolder').removeClass('padding-right-ajust');
    });

    var SearchActive = 0;
    $(document).on('keyup', '#regNrSearch', function() {
        if($(this).val().length == 6 && SearchActive == 0) {
            SearchActive = 1;
            console.log("test");
            var url = 'searchRegNr';
            var callOnSuccess = showRegnr;
            var args = { 'regnr' : $(this).val() };

            setTimeout(function(){ }, 1000);
            ajaxRequest(url, callOnSuccess, args);
        }
    });


    $(document).on('keyup', '#search-manuf-text', function(){
        console.log('searching...');
        var valThis = $(this).val().toLowerCase();
        $('#carManufactorers button').each(function(){
            var text = $(this).data('search-item').toLowerCase();
            (text.indexOf(valThis) == 0) ? $(this).show() : $(this).hide();            
        });
    });


    $(':password').keypress(function(e) { // password written
        enableSaveBtn();
    });


    $(document).on('keyup', '#search-model-text', function(){
        console.log('searching...');
        var valThis = $(this).val().toLowerCase();
        $('#carModels button').each(function(){
            var text = $(this).text().toLowerCase();
            (text.indexOf(valThis) == 0) ? $(this).show() : $(this).hide();            
        });
    });

    //NEW    
    $(document).on('click', '#selectCarManually', function() {

        $('#errorBox').html("");
        $('#errorBox').hide();    

        var url = 'getCarManuf';
        var callOnSuccess = listSearchCarsNew;

        ajaxRequest(url, callOnSuccess)
    });


    $(document).on('change', '#search-car-select', function(){

        $('#search-model-select').empty();
        var url = 'getCarModels';
        var callOnSuccess = listSearchModelsNew;
        var args = { 
            'carBrand' : $(this).val()
        };

        console.log(args);

        ajaxRequest(url, callOnSuccess, args)
    });


    $(document).on('change', '#search-model-select', function(){
 
        var url = 'searchRegNr';
        var callOnSuccess = showRegnr;
        var args = { 
            'modelid' : $("#search-model-select option:selected" ).data('modelid'),
            'ktype' : $( "#search-model-select option:selected" ).data('ktype'),
        };

        ajaxRequest(url, callOnSuccess, args);
    });

    $(document).on('click', '#selectCarPlate', function() {
        $('#errorBox').hide();
        $('#regNrSearch').val("");
        $('#search-reg-mobile').show();
        $('#selectCarManually').show();
        $('#search-car-list').hide();
        $('#search-model-list').hide(); 
        $('#selectCarPlate').hide();
    });




    //OLD

    $(document).on('click', '#search-tabs a[href="#search-model-mobile"]', function() {
        $('#search-model-mobile .alert').remove();
        var url = 'getCarManuf';
        var callOnSuccess = listSearchCars;

        ajaxRequest(url, callOnSuccess)
    });

    $(document).on('click', '#carManufactorers button', function(){
        $('#search-model-mobile .alert').remove();
        var url = 'getCarModels';
        var callOnSuccess = listSearchCars;
        var args = { 
            'carBrand' : $(this).val()
        };

        ajaxRequest(url, callOnSuccess, args)
    });

    $(document).on('click', '#carModels button', function(){
        var url = 'searchRegNr';
        var callOnSuccess = showRegnr;
        var args = { 
            'modelid' : $(this).data('modelid'),
            'ktype' : $(this).data('ktype')
        };

        setTimeout(function(){ }, 1000);

        ajaxRequest(url, callOnSuccess, args);
    });


     
    function ajaxRequest(url, callOnSuccess, args) {
        if (args === undefined) {
            args = null;
        }
        else
        {
            if(("error" in args))
            {
                $('#errorBox').show();
                $('#errorBox').html("");
                $('#errorBox').html(args.error);               
                args = null; 
            }
        }
        $.ajax({
            type: 'get',
            url: url,
            data: args,
            dataType: 'json',
            xhrFields: {
                withCredentials: true
            },
            success: callOnSuccess
        });
    }

    var showRegnr = function (data) {
                
        $('#search-model-mobile .alert').remove();
        if( data.searchResult !== undefined ) {

            $('#search-model-select').empty();
            $('#search-car-select').empty();

            $('#all form').empty();

            $('#all form').append(data.searchResult);

            $('#selectComplete .glyphicon').css("visibility","visible");

            $('#all form').attr('action', 'sok/reg/dack-och-falg/lattmetallfalgar'); 

        } else if(data.error){

            var text = data.error;
            var parts = text.split(" ");

            if(parts[0] == "Registreringsnumret")
            {
                var url = 'getCarManuf';
                var callOnSuccess = listSearchCarsNew;

                var args = { 
                    'error' : data.error,
                };

                ajaxRequest(url, callOnSuccess, args)
            }
            else
            {
                $('#errorBox').show();
                $('#errorBox').html("");
                $('#errorBox').html(data.error);

            }

            /*
            var url = 'getCarManuf';
            var callOnSuccess = listSearchCars;

            ajaxRequest(url, callOnSuccess)
            */
        }
    };




    //NEW
    var listSearchCarsNew = function(data) {
        //$('#errorBox').hide();
        $('#search-reg-mobile').hide();
        $('#selectCarManually').hide();
        $('#selectCarPlate').show();
        $('#search-car-list').show();
        $('#search-car-select').append(data); 
    }

    var listSearchModelsNew = function(data) {
        $('#errorBox').hide();
        $('#search-model-list').show();
        $('#search-model-select').append(data); 
    }

    //OLD
    var listSearchCars = function(data) {
        $('#search-model-mobile .list-group').remove();
        $('#search-model-mobile .inner-addon').remove();
        $('#search-model-mobile').append(data); 
    }

    $(document).ready(function() {
        $('#loading-image').hide();
    });

    $(document).ajaxStart(function(){
        $('#loading-image').show();
    }).ajaxStop(function(){
        $('#loading-image').hide();
    });

    ////////////////////////////
    // Twitter: @mikedevelops
    ////////////////////////////

    // your custome placeholder goes here!
    var ph = "ABC123",
        searchBar = $('#regNrSearch'),
        // placeholder loop counter
        phCount = 0;

    // function to return random number between
    // with min/max range
    function randDelay(min, max) {
        return Math.floor(Math.random() * (max-min+1)+min);
    }

    // function to print placeholder text in a 
    // 'typing' effect
    function printLetter(string, el) {
        // split string into character seperated array
        var arr = string.split(''),
            input = el,
            // store full placeholder
            origString = string,
            // get current placeholder value
            curPlace = $(input).attr("placeholder"),
            // append next letter to current placeholder
            placeholder = curPlace + arr[phCount];
            
        setTimeout(function(){
            // print placeholder text
            $(input).attr("placeholder", placeholder);
            // increase loop count
            phCount++;
            // run loop until placeholder is fully printed
            if (phCount < arr.length) {
                printLetter(origString, input);
            }
        // use random speed to simulate
        // 'human' typing
        }, randDelay(200, 300));
    }  

    // function to init animation
    function placeholder() {
        $(searchBar).attr("placeholder", "");
        printLetter(ph, searchBar);
    }

    window.setTimeout(placeholder, 1500);
    $('.submit').click(function(e){
        phCount = 0;
        e.preventDefault();
        placeholder();
    });
